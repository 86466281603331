.popupOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120vh;
  background-color: rgba(250, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it's above other content */
}

.popupContent {
  display: flex;
  color: white;
  flex-direction: column;
  background: #1e1e1e;
  padding: 20px;
  padding-top: 5px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}


