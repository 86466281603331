.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #2e2e2e; /* Spotify Dark Background */
}

.howToPlay {
  color: #4f9eff
}

.centralPanel {
  height: 100vh;
  width: 80%;
  display: flex;
  max-width: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gamePinDisplay {
  justify-self: center;
  align-self: center;
  /* background: red; */
  /* margin-top: 20px; */
  /* width: 80%; */
  display: flex;
  max-width: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-weight: bold; */
}

.gamePin {
  margin: 0px;
}

.bottomPanel {
  margin-top: 50px;
  width: 80%;
  display: flex;
  max-width: 500px;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}

.logo {
  /* max-width: 100cqw;
  max-height: 90vw; */
  width: 100%;
  margin: 12px;
  border-radius: 4px;
  text-align: center;
  color: white;
  font-size: large;
}

.input, .button, .description {
  box-sizing: border-box; /* Include padding and borders in width calculation */
  width: 100%;
  /* height: 300px; */
  /*height: 200%;*/
  /* color: #1f1f1f; */
  /*max-width: 300px;*/
  margin-block: 12px;
  border-radius: 4px;
  text-align: center;
  color: white;
  font-size: large;
}

.input, .button {
  /* height: 10%; */
  max-height: 50px;
  padding: 10px;
  border: none;
  font-weight: bold;
}

.input {
  text-align: center;
  background-color: #2e2e2e; /* Optional: Set background color for visibility */
  color: white; /* Optional: Set text color for visibility */
  outline: 2px solid lightgray; /* Add an outline to the input field */
}

.input:focus::placeholder {
  color: transparent; /* Replace with your desired color code or name */
}

.input:focus {
  /*background-color: lightgray;*/
  color: white;
}

.join {
  background-color: chocolate;
}

.back {
  background-color: gray;
}

.create {
  background-color: #1DB954; /* Spotify Green */
}

.button {
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}



.create:hover {
  background-color: #1ed760;
}

.join:hover {
  background-color: #F2833EFF;
}


.shake {
  animation: shake 0.25s infinite;
  /*animation-iteration-count: infinite;*/
}


@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(5px);
  }
}

.moveUp {
  transition: transform 0.3s ease-in-out; /* Adjust the duration and timing function as needed */
}

.moveOffScreen {
  animation: moveAnimation 0.35s ease-in-out; /* Adjust the duration as needed */
}

.moveBackOnScreen {
  animation: moveBackAnimation 0.35s ease-in-out; /* Adjust the duration as needed */
}

@keyframes moveBackAnimation {
  0%, 100% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(100%); /* Move to the left */
  }
  50.001% {
    transform: translateX(-100%); /* Move to the left */
  }
  75% {
    transform: translateX(0%); /* Move back to the original position from the right */
  }
}

@keyframes moveAnimation {
  0%, 100% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-100%); /* Move to the left */
  }
  50.001% {
    transform: translateX(100%); /* Move to the left */
  }
  75% {
    transform: translateX(0%); /* Move back to the original position from the right */
  }
}

.textColor {
  color: white;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftAlign {
  align-self: flex-start; /* Aligns the item to the left within the flex container */
}

.rightAlign {
  align-self: flex-end; /* Aligns the item to the right within the flex container */
}


.backButton {

}

/*@keyframes shake {*/
/*  0% { transform: translate(1px, 0px) rotate(0deg); }*/
/*  10% { transform: translate(-1px, 0px) rotate(-1deg); }*/
/*  20% { transform: translate(-3px, 0px) rotate(1deg); }*/
/*  30% { transform: translate(3px, 0px) rotate(0deg); }*/
/*  40% { transform: translate(1px, 0px) rotate(1deg); }*/
/*  50% { transform: translate(-1px, 0px) rotate(-1deg); }*/
/*  60% { transform: translate(-3px, 0px) rotate(0deg); }*/
/*  70% { transform: translate(3px, 0px) rotate(-1deg); }*/
/*  80% { transform: translate(-1px, 0px) rotate(1deg); }*/
/*  90% { transform: translate(1px, 0px) rotate(0deg); }*/
/*  100% { transform: translate(1px, 0px) rotate(-1deg); }*/
/*}*/