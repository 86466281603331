.App {
  /* background: red; */
  text-align: center;
}

body {
  background-color: #2E2E2E; /* Set your desired color */
}

.scoreboard {
  position: relative;
}

html {
  overflow-y: scroll;
  /* background: red; */
}

::-webkit-scrollbar {
    width: 0px; /* Width of the scrollbar */
    height: 16px; /* Height of the scrollbar */
}

.fade-in-scale {
  opacity: 0.1;
  transform: scale(0.1); /* Start at half the size */
  color: white;
  animation: fadeInScale 0.8s linear forwards, bounceScale 1.3s 0.8s linear infinite;
}

@keyframes fadeInScale {
  from {
    opacity: 0.1;
    transform: scale(0.1); /* Start at half the size */
    color: white; /* Start color */
  }
  to {
    opacity: 1;
    transform: scale(1); /* End at full size */
    color: white; /* End color */
  }
}

@keyframes bounceScale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.5);
  }
  
  100% {
    transform: scale(1);
  }
}

.input-placeholder::placeholder {
  color: lightgray;
}

.gray-button {
  text-align: center;
  background-color: #474747; /* Spotify's green color */
  color: white;
  border: none;
  border-radius: 20px; /* Increase corner radius */
  /* padding: 10px 0px; */
  margin: 12px;
  cursor: pointer;
  text-decoration: none; /* Remove link underline */
  display: inline-block; /* Ensure that the link behaves like a button */
  width: 100%; /* Make the button the same width as the div */
  opacity: 0; /* Start with 0 opacity to hide the button */
}

.green-button {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  /* size: 10px; */
  background-color: #1DB954; /* Spotify's green color */
  color: white;
  border: none;
  border-radius: 10px; /* Increase corner radius */
  padding: 10px 0px;
  /* margin: 5px; */
  cursor: pointer;
  text-decoration: none; /* Remove link underline */
  display: inline-block; /* Ensure that the link behaves like a button */
  width: 100%; /* Make the button the same width as the div */
  opacity: 0; /* Start with 0 opacity to hide the button */
  transition: opacity 0.5s ease-in-out; /* Add a transition for the opacity property */
}

.fade-in {
  opacity: 1; /* Change opacity to 1 to make the button fully visible */
}

.white-button {
  text-align: center;
  border: 1px solid white; /* Add "solid" as the border style */
  color: white;
  border-radius: 20px; /* Increase corner radius */
  padding: 10px 40px;
  margin: 12px;
  cursor: pointer;
  text-decoration: none; /* Remove link underline */
  display: inline-block; /* Ensure that the link behaves like a button */
}


.text {
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #111111;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes slideIn {
  from {
    opacity: 0;
    top: 50px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

.slide-in {
  animation: slideIn 0.5s ease-in-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    top: -20px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

.slide-down {
  animation: slideDown 0.5s ease-in-out forwards;
}

@keyframes slideLeft {
  from {
    opacity: 0;
    left: -20px;
  }
  to {
    opacity: 1;
    left: 0;
  }
}

.slide-left {
  animation: slideLeft 0.5s ease-in-out forwards;
}

@keyframes slideUpOut {
  from {
    opacity: 1;

    top: 0px;
  }
  to {
    opacity: 0;

    top: -20px;
  }
}

@keyframes slideDownOut {
  from {
    opacity: 1;

    top: 0px;
  }
  to {
    opacity: 0;

    top: 20px;
  }
}

.slide-up-out {
  animation: slideUpOut 0.5s ease-in-out forwards; 
}

.slide-down-out {
  animation: slideDownOut 0.5s ease-in-out forwards; 
}

@keyframes fadeOut {
  from {
    opacity: 1;

  }
  to {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out forwards;
}

/* .fade-up-out {
  animation: fade 0.5s ease-in-out forwards;
} */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in2 {
  animation: fadeIn 0.75s forwards;
}

.fade-in1 {
  animation: fadeIn 0.5s forwards;
}
