.green-button {
  text-align: center;
  background-color: #1DB954; /* Spotify's green color */
  color: white;
  border: none;
  border-radius: 20px; /* Increase corner radius */
  padding: 10px 0px;
  margin: 12px;
  cursor: pointer;
  text-decoration: none; /* Remove link underline */
  display: inline-block; /* Ensure that the link behaves like a button */
  width: 100%; /* Make the button the same width as the div */
}

.white-button {
  border: 1px solid lightgray;
  text-align: center;
  /* width: 100%; */
  /* border: none; */
  color: lightgray;
  /* font-size: 14px; */
  border-radius: 5px; 
  padding: 10px 20px;
  margin: 12px;
  cursor: pointer;
  text-decoration: none; /* Remove link underline */
  display: inline-block; /* Ensure that the link behaves like a button */
}
