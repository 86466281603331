/* buttonStyles.css */
.commonButton {
  width: 100%;
  min-height: 50px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1;
  background: #3b3b3b;
  color: lightgray;
}

.hiddenAdButton {
  width: 100%;
  min-height: 50px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  /* background: #3b3b3b; */
  /* color: ; */
}

.unchecked {
}

.checked {
  background: grey;
  color: white;
}

.uncheckedSubmitted {
  opacity: 0.5;
  transition: opacity 0.5s ease;
}

.checkedSubmitted {
  background: #fca503;
  color: white;
  transition: background-color 0.5s ease;
}

.correct {
  background: #1DB954;
  transition: background-color 0.5s ease;
  /* Define styles for the "correct" class if needed */
}

.incorrect {

  background: #c20202;
}

.missed {
  background: #1DB954;
  transition: background-color 1.5s ease, transition-delay 2s;
  /* Define styles for the "correct" class if needed */
}