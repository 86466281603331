.loaderContainer {
display: flex;
  justify-content: center;
  align-items: center;
}

.darkGray {

  border: 4px solid darkgray; /* Light grey */
  border-top: 4px solid rgb(101, 101, 101); /* Blue */
}

.white {

  border: 4px solid white; /* Light grey */
  border-top: 4px solid rgb(202, 202, 202); /* Blue */
}

.loader {
    /* background-color: red; */
    /* margin: 0px; */
    
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }